import React from "react";
import { m } from "framer-motion";
import Body from "components/Body";
import ProjectIllustration from "components/SVG/Illustration/ProjectIllustration";
import Scribbles7 from "components/SVG/Scribble/Scribbles7";
import { fadeIn, fadeUp } from "lib/variants";
import Headings from "components/Headings";

export default function ProjectsSection({ data }) {
  return (
    <m.section variants={fadeIn} className="mb-16 2xl:mb-28">
      <div className="container px-4 lg:px-12 mx-auto">
        <div className="flex flex-col lg:flex-row gap-12 items-center">
          <div className="relative w-full lg:w-1/2">
            <Headings
              pretitle={data.field_title_alt}
              title={data.field_title}
              scribble={
                <Scribbles7 className="absolute top-1/2 left-0 -translate-y-1/2 w-1/3 md:w-[8.5vw] text-primary" />
              }
            />
            {data.body && (
              <m.div
                variants={fadeUp}
                initial="out"
                whileInView="enter"
                className="prose prose-dark will-change-transform"
              >
                <Body value={data.body.processed} />
              </m.div>
            )}
          </div>
          <m.div
            variants={fadeUp}
            initial="out"
            whileInView="enter"
            className="relative hidden lg:block w-full lg:w-1/2 will-change-transform"
          >
            <ProjectIllustration className="w-full text-dark lg:max-w-[33.4vw]" />
          </m.div>
        </div>
      </div>
    </m.section>
  );
}
